const LoadIntersectionObserver = () => {
  const promise = new Promise((resolve, reject) => {
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
      'isIntersecting' in window.IntersectionObserverEntry.prototype
    ) {
      // This browser is good
      resolve('Intersection Observer is supported');
    } else {
      // This browser needs the polyfill
      import(
        /* webpackChunkName: 'observer', webpackMode: 'lazy' */ 'intersection-observer'
      )
        .then(() => {
          // Great, it's been imported, either this time or previously
          resolve('Intersection Observer is polyfilled');
        })
        .catch(() => reject(new Error('Intersection Observer is not loaded')));
    }
  });
  return promise;
};

export default LoadIntersectionObserver;
